import { CUSTOMER_TABLE_COLUMN_DATA } from "../constants";

const initialState = {
  version: 1,
  customerEngagementTableColumn: [
    { name: "Customer Number", show: true, key: "customerNumber" },
    { name: "Name", show: true, key: "customerName" },
    { name: "Email", show: true, key: "email" },
    { name: "Mobile Number", show: true, key: "mobileNumber" },
    { name: "Address", show: true, key: "address" },
    { name: "City", show: true, key: "city" },
    { name: "State", show: true, key: "state" },
    { name: "Zip Code", show: true, key: "zipCode" },
    { name: "Visit", show: true, key: "visits" },
    { name: "Spent", show: true, key: "totalSpent" },
    { name: "Loyalty Points", show: true, key: "loyaltyPoints" },
    { name: "Coke CN", show: false, key: "cokeCN" },
    { name: "TAX ID", show: false, key: "b2bTaxID" },
  ],
};
const customerEngagementReducer = (state = initialState, { type, payload }) => {
  switch (type) {
    case CUSTOMER_TABLE_COLUMN_DATA:
      const copy = [...state.customerEngagementTableColumn];
      copy[payload].show = !copy[payload].show;
      return { ...state, customerEngagementTableColumn: copy };
    default:
      return state;
  }
};
export default customerEngagementReducer;
