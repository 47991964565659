import React, { useRef, useState } from "react";
import "./CustomerForm.css";
import logo from "./logo.png";
import ReCAPTCHA from "react-google-recaptcha";
import {
  EMAIL_REQUIRED_MSG,
  NAME_REQUIRED_MSG,
  ONLY_NUMBER_ALLOW,
  ONLY_TEN_NUMBER_ALLOW,
  PHONE_REQUIRED_MSG,
  VALID_EMAIL_MSG,
} from "../utils/validationmsg";
import { postReqwithoutHeader } from "../utils/constant";
import { toast } from "react-toastify";
const CustomerForm = () => {
  const [loading, setLoading] = useState(false);
  const [phoneNumber, setPhoneNumber] = useState(null);
  const [formData, setFormData] = useState({
    customerName: "",
    mobileNumber: "",
    email: "",
    address: "",
    city: "",
    state: "",
    zipCode: "",
    b2bTaxID: "",
  });
  const [formError, setFormError] = useState({
    customerName: "",
    mobileNumber: "",
    email: "",
    address: "",
    city: "",
    state: "",
    zipCode: "",
    b2bTaxID: "",
  });
  const [captchaToken, setCaptchaToken] = useState("");
  // const siteKey = "6LfZJigqAAAAAHUCxlR_1Rxh4j_GswHVwNM-SQgv"; // Site key for AWS QA
  // const siteKey = "6LeIwDIqAAAAAMActH8Uv8NrrDrJrt_cmFolnziu"; // Site key for AWS Staging
  const siteKey = "6LcDSDAqAAAAAADBG7GeSQMWq5EW2zTITSZPXk65"; // Site key for AWS Production

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData((prevState) => ({
      ...prevState,
      [name]: value,
    }));
    const error = { ...formError };
    switch (name) {
      case "customerName":
        error.customerName = value.trim() === "" ? NAME_REQUIRED_MSG : "";
        break;
      case "email":
        error.email =
          value.trim() === ""
            ? EMAIL_REQUIRED_MSG
            : !value.match(
                // eslint-disable-next-line
                /^(?![0-9]+@)\w+([\.-]?\w+)*@[a-zA-Z0-9-][a-zA-Z0-9-][a-zA-Z0-9-]+(?:\.[a-zA-Z][a-zA-Z]+)$/
              )
            ? VALID_EMAIL_MSG
            : "";
        break;
      case "address":
        error.address = value.trim() === "" ? "Please enter address" : "";
        break;
      case "city":
        error.city = value.trim() === "" ? "Please enter your city name" : "";
        break;
      case "state":
        error.state = value.trim() === "" ? "Please enter state name" : "";
        break;
      case "zipCode":
        error.zipCode =
          value === ""
            ? "Please enter Zip Code"
            : !value.match(/^[0-9\b]+$/)
            ? ONLY_NUMBER_ALLOW
            : value.length === 5
            ? ""
            : "Only five number allow";
        break;
      case "b2bTaxID":
        error.b2bTaxID = value === "" ? "Please enter tax ID" : "";
        break;
      default:
        break;
    }
    setFormError(error);
  };
  const convertMobileNumber = (e) => {
    const { name, value } = e.target;
    let myVal = value.replace(/\D/g, "");
    const error = { ...formError };
    switch (name) {
      case "mobileNumber":
        error.mobileNumber =
          myVal === ""
            ? PHONE_REQUIRED_MSG
            : myVal.length > 10
            ? ONLY_TEN_NUMBER_ALLOW
            : myVal.length === 10
            ? ""
            : "Enter a valid 10-digit mobile number";
        break;
      default:
        break;
    }
    setFormError(error);
    if (myVal.length === 10) {
      const intlCode = `${myVal.slice(0, 3)}-${myVal.slice(3, 6)}-${myVal.slice(
        6,
        10
      )}`;
      setPhoneNumber(intlCode);
    } else {
      setPhoneNumber(myVal);
    }
    setFormData({
      ...formData,
      mobileNumber: myVal,
    });
  };
  const validateValue = (e) => {
    const pattern = /^[0-9\b]+$/;
    if (e.target.value === "" || pattern.test(e.target.value)) {
      handleChange(e);
    } else {
      e.preventDefault();
      e.target.value = e.target.value.slice(0, -1);
    }
  };
  const validate = () => {
    let error = true;
    const submitError = { ...formError };
    if (formData.customerName === "") {
      error = false;
      submitError.customerName = NAME_REQUIRED_MSG;
    }
    if (formData.mobileNumber === "") {
      error = false;
      submitError.mobileNumber = PHONE_REQUIRED_MSG;
    }
    if (formData.email === "") {
      error = false;
      submitError.email = EMAIL_REQUIRED_MSG;
    }
    if (formData.address === "") {
      error = false;
      submitError.address = "Customer address is required!";
    }
    if (formData.city === "") {
      error = false;
      submitError.city = "City is required!";
    }
    if (formData.state === "") {
      error = false;
      submitError.state = "State is required!";
    }
    if (formData.zipCode === "") {
      error = false;
      submitError.zipCode = "Zip Code is required!";
    }
    if (formData.b2bTaxID === "") {
      error = false;
      submitError.b2bTaxID = "TAX ID is required!";
    }
    setFormError(submitError);
    return error;
  };
  const recaptchaRef = useRef(null);
  const onChange = (value) => {
    setCaptchaToken(value);
  };
  const handleSubmit = async (e) => {
    e.preventDefault();
    if (!captchaToken) {
      toast.error("Please complete the CAPTCHA verification!", {
        theme: "colored",
        autoClose: 800,
      });
      return;
    }
    if (validate()) {
      const reqBody = {
        ...formData,
      };
      setLoading(true);
      try {
        const response = await postReqwithoutHeader(
          `web/addCustomerDetails`,
          reqBody
        );
        const { status, message } = response?.data;
        if (status === 200) {
          setLoading(false);
          toast.success(message, { theme: "colored", autoClose: 800 });
          setCaptchaToken("");
          if (recaptchaRef.current && recaptchaRef.current.reset) {
            recaptchaRef.current.reset();
          }
          setPhoneNumber(null);
          setFormData({
            customerName: "",
            mobileNumber: "",
            email: "",
            address: "",
            city: "",
            state: "",
            zipCode: "",
            b2bTaxID: "",
          });
          setFormError({
            customerName: "",
            mobileNumber: "",
            email: "",
            address: "",
            city: "",
            state: "",
            zipCode: "",
            b2bTaxID: "",
          });
        }
      } catch (error) {
        setLoading(false);
        const { message } = error.response.data;
        toast.error(message, { theme: "colored", autoClose: 800 });
      }
    } else {
      toast.error("Please fill the required fields!", {
        theme: "colored",
        autoClose: 800,
      });
    }
  };
  return (
    <div>
      <>
        <header>
          <div className="formheader">
            <div className="formlogo">
              <img src={logo} alt="logo" />
            </div>
          </div>
        </header>
        <div className="query-form-wrp">
          <h4 className="heading-form">Customer Form</h4>
          <form className="form-wrp-box">
            <div className="cstm-row">
              <div className="half-wrp">
                <label>Name</label>
                <span style={{ color: "red" }}>*</span>
                <input
                  name="customerName"
                  value={formData?.customerName}
                  onChange={handleChange}
                  type="text"
                  placeholder="Enter your name"
                />
                <p className="errorPara">{formError.customerName}</p>
              </div>
              <div className="half-wrp">
                <label>Email</label>
                <span style={{ color: "red" }}>*</span>
                <input
                  name="email"
                  value={formData?.email}
                  onChange={handleChange}
                  type="email"
                  placeholder="Enter your email"
                />
                <p className="errorPara">{formError.email}</p>
              </div>
              <div className="half-wrp">
                <label>Mobile</label>
                <span style={{ color: "red" }}>*</span>
                <input
                  name="mobileNumber"
                  value={phoneNumber}
                  onChange={(e) => convertMobileNumber(e)}
                  type="text"
                  maxLength={12}
                  placeholder="Enter your mobile number"
                />
                <p className="errorPara">{formError.mobileNumber}</p>
              </div>
              <div className="half-wrp">
                <label>Address</label>
                <span style={{ color: "red" }}>*</span>
                <input
                  name="address"
                  value={formData?.address}
                  onChange={handleChange}
                  type="text"
                  placeholder="Enter your address"
                />
                <p className="errorPara">{formError.address}</p>
              </div>
              <div className="half-wrp">
                <label>City</label>
                <span style={{ color: "red" }}>*</span>
                <input
                  name="city"
                  value={formData?.city}
                  onChange={handleChange}
                  type="text"
                  placeholder="Enter your city"
                />
                <p className="errorPara">{formError.city}</p>
              </div>
              <div className="half-wrp">
                <label>State</label>
                <span style={{ color: "red" }}>*</span>
                <input
                  name="state"
                  value={formData?.state}
                  onChange={handleChange}
                  type="text"
                  placeholder="Enter your state"
                />
                <p className="errorPara">{formError.state}</p>
              </div>
              <div className="half-wrp">
                <label>Zip Code</label>
                <span style={{ color: "red" }}>*</span>
                <input
                  name="zipCode"
                  value={formData?.zipCode}
                  onChange={validateValue}
                  type="text"
                  maxLength={5}
                  placeholder="Enter your zip code"
                />
                <p className="errorPara">{formError.zipCode}</p>
              </div>
              <div className="half-wrp">
                <label>TAX ID</label>
                <span style={{ color: "red" }}>*</span>
                <input
                  name="b2bTaxID"
                  value={formData?.b2bTaxID}
                  onChange={handleChange}
                  type="text"
                  maxLength={15}
                  placeholder="Enter TAX ID"
                />
                <p className="errorPara">{formError.b2bTaxID}</p>
              </div>
              <div className="half-wrp">
                <ReCAPTCHA
                  ref={recaptchaRef}
                  sitekey={siteKey}
                  onChange={onChange}
                  value={captchaToken}
                />
              </div>
              <div className="full-wrp submit-btn-wrp">
                <button type="button" onClick={handleSubmit} disabled={loading}>
                  {loading ? "Submitting..." : "Submit"}
                </button>
              </div>
            </div>
          </form>
        </div>
      </>
    </div>
  );
};
export default CustomerForm;
