export const NAME_REQUIRED_MSG = "Name is required!";
export const EMAIL_REQUIRED_MSG = "Email is required!";
export const PHONE_REQUIRED_MSG = "Mobile number is required!";
export const VALID_NAME_MSG = "Please enter valid name!";
export const VALID_EMAIL_MSG = "Please enter valid email!";
export const MIN_THREE_CHAR_REQ = "Minimum 3 characters are required!";
export const ONLY_NUMBER_ALLOW = "Only number allow here!";
export const ONLY_TEN_NUMBER_ALLOW = "Number must be 10 digits!";
export const VALID_ADDRESS_MSG = "Please enter valid address!";
export const VALID_MOBILE_NUMBER = "Enter valid mobile number!";
export const DEPARTMENT_NAME_REQUIRED = "Department Name is Required!";
export const SELECT_ANY_VALUE = "Please select any value!";
export const CUSTOMER_AGE_REQUIRED = "Please enter customer age!";
export const SELECT_ATLEAST_ONE_TAX = "Please select at least one tax!";
export const MERCHANT_NAME_REQUIRED = "Merchant Name is Required!";
export const PRODUCT_NAME_REQUIRED = "Product Name is Required!";
export const SELLING_PRICE_REQUIRED = "Selling Price is Required!";
export const UPC_REQUIRED = "UPC is Required!";
export const PLEASE_SELECT_ANY_DEPARTMENT =
  "Please select at least one department!";
export const CONA_ITEM_NUMBER_REQUIRED = "Please Enter CONA Item Number!";
export const COST_PRICE =
  "Value should be less than or equal to the selling price!";
